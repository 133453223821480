<template>
    <div class="box">

        <br>
        <div>
            我的地址: {{ $global.walletAddressALittle(wallet, 6) }}
        </div>
        <div>我的上级: {{ $global.walletAddressALittle(pageConfig['shangji'].value, 6) }}</div>

        <br>

        <div>查询自身算力: {{ pageConfigOther['suanli'].value[0] }}</div>
        <div>查询直推算力: {{ pageConfigOther['suanli'].value[1] }}</div>
        <div>查询团队算力: {{ pageConfigOther['suanli'].value[2] }}</div>

        <div>
            <input v-model="pageConfigBtn['zhiya'].value" type="text" style="width: 200px;" placeholder="请输入质押数量">
            <button @click="abiFunApproveUSDT(pageConfigBtn['zhiya'])">质押</button>
        </div>



        <div>查询全网算力: {{ pageConfig['quanwangsuanli'].value }}</div>
        <div>查询个人算力: {{ pageConfig['gerensuanli'].value }}</div>
        <div>查询收益: {{ pageConfig['shouyi'].value }}</div>

        <div>
            <button @click="abiFun(pageConfigBtn['lingqu'])">领取收益</button>
        </div>
        <br>
        <div>查询收益记录:

            <van-list v-if="pageConfig['shouyijilu'].value.length > 0" finished-text="没有更多了">
                <van-cell v-for="(item, index) in list" :key="index" :title="'记录' + (index + 1)" />
            </van-list>
            <div v-else style="padding: 5px;font-size: 12px;text-align: center;">
                暂无收益
            </div>
        </div>

        <div>下级:

            <van-list v-if="pageConfig['xiaji'].value.length > 0" finished-text="没有更多了">
                <!-- <van-cell :key="999">
                    <template #default>
                        <div class="table-title table-td">
                            <div>
                                自身 {{ }}
                            </div>
                            <div>
                                直推
                            </div>
                            <div>
                                团队
                            </div>
                        </div>

                    </template>
                </van-cell> -->
                <van-cell v-for="(item, index) in pageConfig['xiaji'].value" :key="index">
                    <template #default>
                        <div class="table-td">
                            <div>
                                {{ item }}
                            </div>
                        </div>
                    </template>
                </van-cell>
            </van-list>
            <div v-else style="padding: 5px;font-size: 12px;text-align: center;">
                暂无下级
            </div>
        </div>

        <br>
        <button @click="$router.push('/Config')">设置地址</button>
    </div>
</template>

<script>
import { approveToken, allowanceToken, abi, ethers, contractToken } from '@/views/MINT/js/contractMethods'
export default {
    computed: {
        wallet() {
            return this.$store.state.wallet;
        },
        bindState() {
            return this.$store.state.bindStateNFT;
        },
        bindAddress() {
            return this.$store.state.bindAddressNFT;
        }
    },
    watch: {
        wallet(val) {
            if (val) {
                this.loadData()
            }
        }
    },

    data() {
        return {
            list: [{}, {}, {}],
            list2: [{}],
            approveState: false,

            pageConfigOther: {
                suanli: {
                    label: '算力',
                    abiName: 'getPowerInfo',
                    value: [0, 0, 0],
                    params: ['wallet']
                },
            },

            pageConfigBtn: {
                zhiya: {
                    label: '质押',
                    abiName: 'mint',
                    params: ['']
                },
                lingqu: {
                    label: '领取',
                    abiName: 'withdraw',
                },

            },

            pageConfig: {
                shangji: {
                    label: '推荐人',
                    abiName: 'getRecommend',
                    isNotNumber: true,
                    value: '',
                    params: ['wallet']
                },
                xiaji: {
                    label: '下级',
                    abiName: 'getLow',
                    isNotNumber: true,
                    value: [],
                    params: ['wallet']
                },

                quanwangsuanli: {
                    label: '全网算力',
                    abiName: 'getTotalPower',
                    value: '',
                    params: []
                },
                gerensuanli: {
                    label: '个人算力',
                    abiName: 'getPower',
                    value: '',
                    params: ['wallet']
                },
                shouyi: {
                    label: '收益',
                    abiName: 'getReward',
                    value: '',
                    params: ['wallet']
                },
                shouyijilu: {
                    label: '收益记录',
                    abiName: 'getRewardList',
                    value: [],
                    params: ['wallet']
                },
            }
        }
    },
    created() {
        this.setContent()
        this.loadData()
    },

    methods: {
        loadData() {
            const wallet = this.wallet
            // const wallet = '0x4E757051Ee465846823A049c6969f0658983E19E'



            this.$store.commit("SetLoading", false);

            const { pageConfig, pageConfigNFT, abi } = this

            for (let key in pageConfig) {
                abi(key)
            }

            this.allowance()
            // 获取算力
            this.SuanliFun()
        },


        async setContent() {

            const shareurl = this.$route.query.shareurl

            const ongHaveBind = await this.testBind(this.wallet)

            if (ongHaveBind) {
                console.log('bingAddress', ongHaveBind)
                this.$store.commit('SetBindAddressNFT', ongHaveBind)
                this.$store.commit('SetBindStateNFT', true)
            } else {

                if (shareurl) {

                    console.log(3)
                    if (shareurl === this.wallet) {

                        this.$store.commit('SetBindAddressNFT', contractToken.address)
                    } else {


                        const shareUrlHaveBind = await this.testBind(shareurl)
                        if (shareUrlHaveBind) {

                            this.$store.commit('SetBindAddressNFT', shareurl)
                        } else {
                            console.log(7)
                            this.$store.commit('SetBindAddressNFT', contractToken.address)
                        }
                    }
                } else {
                    console.log(8)
                    this.$store.commit('SetBindAddressNFT', contractToken.address)
                }
            }
        },

        SuanliFun() {



            const record = this.pageConfigOther['suanli']
            abi(
                {
                    abiName: record.abiName,
                    params: [this.wallet],
                    type: "check",
                },
                ({ code, data }) => {
                    if (code == 200) {

                        this.pageConfigOther['suanli'].value = data.map(item => {
                            return item == 0 ? 0 : (item / (10 ** 18)).toFixed(2)
                        })

                        console.log(record.label, "-", record.abiName, ":", this.pageConfigOther['suanli'].value)
                        this.$forceUpdate()
                    }
                }
            );
        },

        abi(key) {
            const record = this.pageConfig[key]
            let params = record.params
            if (record.params.length > 0) {
                if (record.params[0] === 'wallet') {
                    params[0] = this.wallet
                }
            }
            abi(
                {
                    abiName: record.abiName,
                    params: params,
                    type: "check",
                },
                ({ code, data }) => {
                    if (code == 200) {

                        if (this.pageConfig[key].isNotNumber) {
                            this.pageConfig[key].value = data
                        } else {
                            if (this.pageConfig[key].isNotNeed18) {
                                this.pageConfig[key].value = data == 0 ? 0 : (data);
                            } else {
                                this.pageConfig[key].value = data == 0 ? 0 : (data / (10 ** 18)).toFixed(this.pageConfig[key].toFixedNumber === 0 ? 0 : 2);
                            }
                        }

                        console.log(record.label, "-", record.abiName, ":", this.pageConfig[key].value)
                        this.$forceUpdate()
                    }
                }
            );
        },



        abiFun(record) {
            if (!this.wallet) {
                this.showSuccessMessage("请先连接钱包!");
            } else {

                this.$store.commit("SetLoadingText", `领取中`);
                this.$store.commit("SetLoading", true);

                abi(
                    {
                        abiName: record.abiName,
                        params: record.params ? record.params : []
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            this.loadData()
                        } else {
                            this.showSuccessMessage("取消操作!");
                            this.$store.commit("SetLoading", false);
                        }
                    }
                );
            }
        },

        /* 先根据指示授权 再执行 */
        /* 1绑定2授权3私募 */
        async abiFunApproveUSDT(record) {

            let status = false
            if (!this.wallet) {

                this.showSuccessMessage("请先连接钱包!");
            } else {



                status = await this.Bind(record.value)
                if (!status) return

                status = await this.approve(record.value)
                if (!status) return


                this.$store.commit("SetLoadingText", `质押中`);
                const Number = (ethers.BigNumber.from(Number(record.params[0]).toFixed(0))).mul('1000000000000000000')

                abi(
                    {
                        abiName: record.abiName,
                        params: [Number]
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            this.loadData()
                        } else {
                            this.showSuccessMessage("取消操作");
                        }
                        this.$store.commit("SetLoading", false);
                    }
                );
            }


        },


        /* 查询绑定人 */
        testBind(address) {

            const wallet = address
            // const wallet = '0x4E757051Ee465846823A049c6969f0658983E19E'
            console.log(wallet)
            return new Promise(resolve => {
                abi({
                    abiName: "getRecommend",
                    type: "check",
                    params: [wallet],
                },
                    async ({
                        code,
                        data,
                        message
                    }) => {
                        console.log('bindAddress', data)
                        if (!data || data == '0x0000000000000000000000000000000000000000') {
                            resolve(false)
                        } else {
                            resolve(data)
                        }
                    }
                );
            })

        },


        Bind() {

            this.$store.commit("SetLoadingText", '绑定中');
            this.$store.commit("SetLoading", true);
            if (this.bindAddress === this.wallet) {

                this.showSuccessMessage("无法与自己绑定!");
                this.$store.commit("SetLoading", false);
                resolve(false)
            } else {
                return new Promise((resolve) => {
                    if (!this.bindState) {
                        abi({
                            abiName: 'bind',
                            params: [this.bindAddress, this.wallet]
                        }, ({ code, message }) => {
                            if (code == 200) {
                                this.$store.commit("SetBindStateNFT", true);
                                this.$store.commit("SetBindAddressNFT", this.bindAddress);
                                resolve(true)
                            } else {
                                this.showSuccessMessage("无效账户无法推广!");
                                this.$store.commit("SetBindStateNFT", false);
                                this.$store.commit("SetLoading", false);
                                resolve(false)
                            }
                        })
                    } else {
                        resolve(true)
                    }
                })
            }
        },


        async allowance() {

            const state = await allowanceToken()
            console.log('state', Number(state))

            this.approveState = (state > 0 && (state / (10 ** 18)) > 1000) ? true : false
        },


        approve(usdt) {

            this.$store.commit("SetLoadingText", '授权中');
            this.$store.commit("SetLoading", true);
            return new Promise((resolve) => {
                approveToken(usdt, (data) => {
                    if (data) {
                        this.approveState = true
                        resolve(true)
                    } else {
                        this.approveState = false
                        this.$store.commit("SetLoading", false);
                        this.showSuccessMessage("取消操作!");
                        this.$store.commit("SetLoading", false);
                        resolve(false)
                    }
                })
            })

        },
    }
}
</script>

<style scoped lang="scss"> .box {
     margin: 80px auto 100px;
     width: 90%;
     background-color: #fff;
     border-radius: 10px;
     padding: 10px;
     box-shadow: 5px 5px 5px rgba(0, 0, 0, .3);

     >div {
         margin-bottom: 10px;
     }

     .table-title {
         font-weight: 700;
     }

     .table-td {
         display: flex;

         >div {
             flex: 1;
         }
     }
 }
</style>