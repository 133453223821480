<template>
    <div class="config">
        <textarea v-model="address" name="" id="" cols="30" rows="10"></textarea>
        <button @click="SetAddress">设置地址</button> 
        <button @click="back" style="margin-left: 30px;">返回页面</button>
    </div>
</template>

<script>
import contractToken from '@/views/MINT/js/contract'
export default {
    data() {
        return {
            address: contractToken.address
        }
    },  
    created() {
        const localStorageAddress = localStorage.getItem('contractAddress')
        if(localStorageAddress) {
            this.address = localStorageAddress
        }else if(contractToken.address) {
            this.address = contractToken.address
        }
    },
    methods: {
        SetAddress() {
            localStorage.setItem('contractAddress', this.address)
            this.showSuccessMessage("设置成功!");
        },
        back() {
            this.$router.back()
            // this.$router.replace(`/?shareurl=${this.$route.query.shareurl || ''}`)
        }
    }
}
</script>

<style>
.config {
    padding: 0 40px;
    padding-top: 70px;

    textarea {
        width: 100%;
    }
}
</style>