import store from '@/store'
import { ethers } from 'ethers'
import contract from './contractNewNFT'
import contractToken from './contract'
import approves from './approve'


const userChainId = store.state.userChainId

const GetWalletAddress = () => {
  return new Promise(async resolve => {


    if (typeof window.ethereum !== "undefined") {
      let addr = await ethereum.request({ method: "eth_requestAccounts" }); //授权连接钱包
      console.log("用户钱包地址:", addr[0]);
      resolve(addr[0])
    } else {
      alert("未安装钱包插件！");
    }
  })

}

/* 获取当前网络ID */
const GetCurrentChainId = () => {
  return new Promise(async resolve => {

    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await provider.getNetwork();
      resolve(network)
    } else {
      alert("未安装钱包插件！");
    }
  })

}


/* 切换到用户网络 */
const CutUserInternet = () => {
  return new Promise(async resolve => {
    if (GetCurrentChainId().chainId == userChainId) {
      resolve(true)
    } else {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: ethers.utils.hexValue(userChainId) }],
        });
        resolve(true)
      } catch(error) {
        resolve(false)
      }
    }
  })

}



// 质押
const abi = async (data, callback) => {

  if (!window.ethereum) return
  await window.ethereum.enable()

  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const useAccount = await signer.getAddress()


  // console.log(omg_contract.methods);

  // const bamount = (ethers.BigNumber.from(Number(data).toFixed(0))).mul('1000000000000000000')

  let contractTokenAdress = localStorage.getItem('contractAddress') || contractToken.address
  
  if (data.type === 'check') {
    const lp = new ethers.Contract(
      contractTokenAdress,
      contractToken.abi,
      signer
    )
    console.log(data)
    try {
      await lp[data.abiName](...(data.params || [])).then(res => {
        callback({
          code: 200,
          message: '',
          data: res
        })
      })
    } catch (err) {
      console.log(`请求失败${data.abiName}`, err);
      callback({
        code: 401,
        message: '取消操作'
      })
    }
  } else {
    /**
     * 
     * 200 成功
     * 
     * 401 取消操作
     * 
     * 400 操作失败
     */
    // alert('暂未开放!')
    // return 


    if (!await CutUserInternet()) {
      callback({
        code: 401,
        message: '取消操作'
      })
      return
    }


    var Web3 = require('web3');
    var web3 = new Web3(Web3.givenProvider)
    const omg_contract = new web3.eth.Contract(contractToken.abi, contractTokenAdress)

    let sendData = {
      from: useAccount
    }

    if (data.sendValue) sendData['value'] = data.sendValue;
    console.log(data.params);

    omg_contract.methods[data.abiName](...data.params || []).send(sendData)
      .on('transactionHash', function (hash) {
        // console.log('hash', hash);
      })
      .on('receipt', function (receipt) {
        console.log('receipt', receipt);
        callback({
          code: 200,
          data: receipt,
          message: ''
        })
      })
      .on('confirmation', function (confirmationNumber, receipt) {
        // console.log('confirmationNumber', confirmationNumber);
        // console.log('receipt', receipt);
      })
      .on('error', function (err) {
        console.log(err)
        callback({
          code: 401,
          message: '取消操作'
        })
      });
  }

}


// 检测是否授权
const allowance = async (callback) => {

  if (!window.ethereum) return
  await window.ethereum.enable()

  const provider = new ethers.providers.Web3Provider(window.ethereum)

  const signer = provider.getSigner()

  const useAccount = await signer.getAddress()

// 0x8604258e6b0922948754B0E8B4714f55Bcb91644

  const lp = new ethers.Contract(
    '0x55d398326f99059fF775485246999027B3197955',
    approves.abi,
    signer
  )

  const contractToken2 = localStorage.getItem('contractAddress') || contractToken.address
  return new Promise(async (resolve, reject) => {
    try {
      await lp.allowance(useAccount, contractToken2).then(res => {
        console.log(res);
        resolve(res)
      })
    } catch (err) {
      console.log(err);
      reject(false)
    }
  })
}

// 授权
const approve = async (callback) => {
  if (!window.ethereum) return
  await window.ethereum.enable()

  var Web3 = require('web3');
  var web3 = new Web3(Web3.givenProvider)

  const provider = new ethers.providers.Web3Provider(window.ethereum)

  const signer = provider.getSigner()

  const useAccount = await signer.getAddress()

  const to = localStorage.getItem('contractAddress') || contractToken.address
  const bamount = (ethers.BigNumber.from(99999999999999)).mul('1000000000000000000')

  const omg_contract = new web3.eth.Contract(approves.abi, '0x55d398326f99059fF775485246999027B3197955')
  omg_contract.methods.approve(to, bamount).send({
    from: useAccount
  })
    .on('receipt', function (receipt) {
      console.log('receipt', receipt);
      callback(receipt.transactionHash)
    })
    .on('error', function (err) {
      console.log(err)
      callback(false)
    });
}


// token检测是否授权
const allowanceToken = async (callback) => {

  if (!window.ethereum) return
  await window.ethereum.enable()

  const provider = new ethers.providers.Web3Provider(window.ethereum)

  const signer = provider.getSigner()

  const useAccount = await signer.getAddress()


  const lp = new ethers.Contract(
    approves.address,
    approves.abi,
    signer
  )
  let contractTokenAdress = localStorage.getItem('contractAddress') || contractToken.address

  return new Promise(async (resolve, reject) => {
    
    try {
      await lp.allowance(useAccount, contractTokenAdress).then(res => {
        // console.log(res);
        resolve(res)
      })
    } catch (err) {
      console.log(err);
      reject(false)
    }
  })
}

// token授权
const approveToken = async (callback) => {
  if (!window.ethereum) return
  await window.ethereum.enable()

  var Web3 = require('web3');
  var web3 = new Web3(Web3.givenProvider)

  const provider = new ethers.providers.Web3Provider(window.ethereum)

  const signer = provider.getSigner()

  const useAccount = await signer.getAddress()

  let contractTokenAdress = localStorage.getItem('contractAddress') || contractToken.address

  const to = contractTokenAdress;
  const bamount = (ethers.BigNumber.from(99999999999999)).mul('1000000000000000000')

  const omg_contract = new web3.eth.Contract(approves.abi, approves.address)
  omg_contract.methods.approve(to, bamount).send({
    from: useAccount
  })
    .on('receipt', function (receipt) {
      console.log('receipt', receipt);
      callback(receipt.transactionHash)
    })
    .on('error', function (err) {
      console.log(err)
      callback(false)
    });
}


export {

  GetWalletAddress,
  GetCurrentChainId,
  approve,
  allowance,
  allowanceToken,
  approveToken,
  abi,


  contract,
  contractToken,
  ethers
}