<template>
    <div class="Home">
        <div class="himg">
            <img src="../../assets/img/1115/组 7.png" alt="" srcset="">
        </div>


        <!-- 推荐人地址 -->
        <div class="tjrdz">
            <div class="t-name">
                <div class="t-name--left">
                    <img src="../../assets/img/1115/推荐人 (2).png" alt="">
                    <div class="left-text">
                        {{ $t('testPage.us18') }}:
                    </div>
                </div>
                <div class="t-name--right">
                    {{ $global.walletAddressALittle(bindAddress, 6) }}
                </div>
            </div>

            <div class="tjr-content">
                <div class="tjr-left">
                    <img src="../../assets/img/1115/w我的.png" alt="">
                    <div class="my-address">{{ $t('testPage.us19') }}</div>
                    <div class="my-key">{{ $global.walletAddressALittle(wallet, 6) }}</div>
                </div>
                <div class="tjr-right" @click="Share">
                    <img class="tjr-img" src="../../assets/img/1115/组 3.png" alt="" srcset="">
                    <img class="share-img" src="../../assets/img/1115/分享 (3).png" alt="">
                    <div class="right-share">{{ $t('wakuang1.fengxiang') }}</div>
                </div>
            </div>
        </div>

        <!-- 所需VCB -->
        <div class="need-vcb">
            <div class="vcb-title">
                <div class="vcb-text">{{ $t('testPage.us22') }}</div>
                <div class="vcb-value">{{ (pageConfigOther['suanli'].value[0] || 0) * 1000 }}</div>
            </div>

            <div class="vcb-content">
                <div class="v-top">
                    <div class="v-1">
                        <div class="v-title">{{ $t('testPage.us20') }}</div>
                        <div class="v-value"> {{ pageConfigOther['suanli'].value[3] }}T</div>
                    </div>
                    <div class="v-2">
                        <div class="v-title">{{ $t('testPage.us21') }}</div>
                        <div class="v-value v2-v">V{{ pageConfig['dengji'].value || '0' }}</div>
                    </div>
                </div>
                <div class="v-bottom">
                    <div class="v-3">
                        <div class="v-title" :class="$store.state.language == '英语' ? 'yingyu' : ''">{{ $t('testPage.us03') }}</div>
                        <div class="v-value">{{ pageConfig['quanwangsuanli'].value }}T</div>
                    </div>
                    <div class="v-4">
                        <div class="v-title">{{ $t('testPage.us091') }}</div>
                        <div class="v-value">{{ pageConfigOther['suanli'].value[0] || 0 }}T</div>
                    </div>
                </div>
            </div>
        </div>


        <!-- 算力 -->
        <div class="suanli-card">
            <div class="s-card card1">
                <div class="card-left">
                    <div class="c-left--top">{{ $t('testPage.us09') }}</div>
                    <div class="c-left--bottom">{{ pageConfigOther['suanli'].value[0] || 0 }}T</div>
                </div>
                <div class="card-right">
                    <img src="../../assets/img/1115/用户-fill (1).png" alt="" srcset="">
                </div>
            </div>
            <div class="s-card card2">
                <div class="card-left">
                    <div class="c-left--top">{{ $t('testPage.us10') }}</div>
                    <div class="c-left--bottom">{{ pageConfigOther['suanli'].value[1] || 0 }}T</div>
                </div>
                <div class="card-right">
                    <img src="../../assets/img/1115/直推.png" alt="" srcset="">
                </div>
            </div>
            <div class="s-card card3">
                <div class="card-left">
                    <div class="c-left--top">{{ $t('testPage.us11') }}</div>
                    <div class="c-left--bottom">{{ pageConfigOther['suanli'].value[2] || 0 }}T</div>
                </div>
                <div class="card-right">
                    <img src="../../assets/img/1115/团队 (2).png" alt="" srcset="">
                </div>
            </div>
        </div>

        <!-- 收益 -->
        <div class="income">
            <div class="income-title">
                <div class="i-title-text">{{ $t('testPage.us12') }}</div>
                <div class="i-title-val">{{ pageConfig['shouyi'].value || 0 }} VC DAO</div>
            </div>
            <div class="income-btn" @click="abiFun(pageConfigBtn['lingqu'])">
                {{ $t('testPage.us13') }}
            </div>
        </div>

        <!-- 查询算力 -->
        <div class="search-hash">
            <div class="hash-title">
                <div class="t-left">{{ $t('testPage.us08') }}</div>
                <div class="t-right">1 T ≈ {{ pageConfigOther['suanligongshi'].value }} VC DAO</div>
            </div>
            <div class="hash-content">
                <!-- <div class="hash-left">
                    <div class="input-num">
                        <div class="input-pl">{{ $t(`testPage.us01`) }}</div>
                        <input type="text" v-model="pageConfigBtn['zhiya'].value">
                    </div>
                    <div class="zy-btn" v-if="Number(pageConfigBtn['zhiya'].value) >= Number(pageConfigOther['suanligongshi'].value)" @click="abiFunApprove(pageConfigBtn['zhiya'], 'token')">{{ $t('testPage.us02') }}</div>
                    <div class="zy-btn" v-else @click="errorMessage('token')">{{ $t('testPage.us02') }}</div>
                </div> -->
                <div class="hash-right">
                    <div class="input-num">
                        <div class="input-pl">{{ $t(`testPage.us01`) }}</div>
                        <input type="text" v-model="pageConfigBtn['zhiyau'].value">
                    </div>
                    <div class="zynum-img">
                        <img src="../../assets/img/1115/图层 10.png" alt="" srcset="">
                    </div>
                    <div v-if="Number(pageConfigBtn['zhiyau'].value) >= 100" class="zy-btn u" @click="abiFunApprove(pageConfigBtn['zhiyau'], 'usdt')">{{ $t('testPage.us02') }} U</div>
                    <div v-else class="zy-btn u" @click="errorMessage('usdt')">{{ $t('testPage.us02') }} U</div>
                </div>
            </div>
        </div>

         <!-- 选择查询记录 -->
        <div class="choose-record">
            <van-dropdown-menu>
                <van-dropdown-item v-model="value1" :options="option1" />
            </van-dropdown-menu>
        </div>

        <!-- 质押记录 -->
         <div class="zy-list">
            <div class="zy-title">{{zyTitle}}</div>
            <img src="../../assets/img/1115/直线 1 拷贝.png" alt="">

            <div class="zy-content" v-if="value1 === 'xj'">
                <div v-if="pageConfigOther['xiajidizhi'].value.length > 0">
                <div class="record-item" v-for="(record, index) in pageConfigOther['xiajidizhi'].value" :key="index">
                    <div class="i-left">
                        <div class="r-title">
                            {{ $global.walletAddressALittle(record.address, 6) }}
                            <span style="margin-left:3px;font-family: 'fangsong';color: #abc;">
                                v<span style="font-style: italic;">{{ record.dengji }}</span>
                            </span>
                        </div>
                        <div class="r-time">{{ $t('testPage.us09') }}: {{ record.jiangli }}T</div>
                    </div>
                    <div class="i-right level"><span style="font-style: italic;">{{ filterNumber20(record.suanli) }}T</span>
                    </div>
                </div>
                </div>
                <div v-else style="padding: 5px;font-size: 12px;text-align: center;padding-top: 40px;">
                    {{ $t('testPage.us25') }}
                </div>
            </div>


            <!-- 质押 -->
            <div class="zy-content" v-if="value1 === 'zy'">
                <div v-if="pageConfigOther['zhiyajilu'].value.length > 0">
                <div class="record-item" v-for="(record, index) in pageConfigOther['zhiyajilu'].value" :key="index">
                    <div class="i-left">
                        <div class="r-title">
                            <div class="tag tag-u" v-if="record[2] == 1">
                                U
                            </div>
                            <div class="tag tag-t" v-else>
                                T
                            </div>
                            <div>
                                {{ filterNumber20(record[1]) }}T
                            </div>
                        </div>
                        <div class="r-time">{{ filterDate(record[0]) }}</div>
                    </div>
                    <!-- <div class="i-right">{{ record[2] == 1 ? 'U' : 'T' }}</div> -->
                </div>
                </div>
                <div v-else style="padding: 5px;font-size: 12px;text-align: center;padding-top: 40px;">
                    {{ $t('testPage.us25') }}
                </div>
            </div>


            <div class="zy-content" v-if="value1 === 'lq'">
                <div v-if="pageConfigOther['lingqujilu'].value.length > 0">
                <div class="record-item" v-for="(record, index) in pageConfigOther['lingqujilu'].value" :key="index">
                    <div class="i-left">
                        <div class="r-title">
                            <div>
                                {{$t('testPage.us28')}}: {{ filterNumber18(record[0]) }} VC DAO
                            </div>
                        </div>
                        <div class="r-time">
                            {{$t('testPage.us29')}}: {{ filterNumber18(record[1]) }} VC DAO
                        </div>
                    </div>
                </div>
            </div>
            <div v-else style="padding: 5px;font-size: 12px;text-align: center;padding-top: 40px;">
                {{ $t('testPage.us25') }}
            </div>
            </div>


            <!-- 收益 -->
            <div class="zy-content" v-if="value1 === 'sy'">
                <div v-if="pageConfigOther['shouyijilu'].value.length > 0">
                <div class="record-item" v-for="(record, index) in pageConfigOther['shouyijilu'].value" :key="index">
                    <div class="i-left">
                        <div class="r-title">{{ filterNumber18(record[1]) }} VC DAO</div>
                        <div class="r-time">{{ filterDate(record[0]) }}</div>
                    </div>
                    <div class="i-right">{{ record[2] }}</div>
                </div>
                </div>
                <div v-else style="padding: 5px;font-size: 12px;text-align: center;padding-top: 40px;">
                    {{ $t('testPage.us25') }}
                </div>
            </div>
         </div>
    </div>
</template>

<script>
import { approveToken, allowanceToken, abi, ethers, contractToken, approve, allowance } from './js/contractMethods'
export default {
    computed: {
        wallet() {
            return this.$store.state.wallet;
            // return '0xd2CBa655FA91386f6141FC75AbDF8553AeDfE7cA'
        },
        bindState() {
            return this.$store.state.bindStateNFT;
        },
        bindAddress() {
            return this.$store.state.bindAddressNFT;
        },
        zyTitle() {
            const val = this.option1.find(item => this.value1 === item.value)
            return val.text
        }
    },
    watch: {
        wallet(val) {
            if (val) {
                this.loadData()
            }
        },
        '$store.state.language': function(val) {
            this.option1 = [
                { text: this.$t('testPage.us23'), value: 'xj' },
                { text: this.$t('testPage.us24'), value: 'zy' },
                { text: this.$t('testPage.us27'), value: 'lq' },
                { text: this.$t('testPage.us14'), value: 'sy' },
            ] 
        }
    },

    data() {
        return {
            value1: 'xj',
            option1: [
                { text: this.$t('testPage.us23'), value: 'xj' },
                { text: this.$t('testPage.us24'), value: 'zy' },
                { text: this.$t('testPage.us27'), value: 'lq' },
                { text: this.$t('testPage.us14'), value: 'sy' },
            ],
            threeAlo: [
                {
                    i18nKey: 'testPage.us05',
                    label: '全网算力: ',
                    value: '0'
                },
                {
                    i18nKey: 'testPage.us06',
                    label: '直推算力: ',
                    value: '0'
                },
                {
                    i18nKey: 'testPage.us07',
                    label: '团队算力: ',
                    value: '0'
                }
            ],

            list: [{}, {}, {}],
            list2: [{}],
            approveState: false,

            pageConfigOther: {
                suanli: {
                    label: '算力',
                    abiName: 'getPowerInfo',
                    value: [0, 0, 0, 0],
                    params: ['wallet']
                },
                gerensuanli: {
                    label: '个人算力',
                    abiName: 'getPower',
                    value: '',
                    params: ['wallet']
                },
                suanligongshi: {
                    label: '算力单价',
                    abiName: 'getTokenPrice',
                    value: 0,
                    params: [100]
                },

                xiajidizhi: {
                    label: '下级地址',
                    abiName: 'getLow',
                    value: [],
                    params: ['wallet']
                },
                tigongsuanli: {
                    label: '提供算力',
                    abiName: 'getProvideAmount',
                    value: '',
                    params: ['wallet']
                },
                xiajidengji: {
                    label: '下级等级',
                    abiName: 'getUserLevel',
                    value: '',
                    params: ['wallet']
                },
               

                zhiyajilu: {
                    label: '质押记录',
                    abiName: 'getMintList',
                    value: [],
                    params: ['wallet']
                },

                shouyijilu: {
                    label: '收益记录',
                    abiName: 'getRewardList',
                    value: [],
                    params: ['wallet']
                },

                lingqujilu: {
                    label: '领取记录',
                    abiName: 'getReceiveList',
                    value: [],
                    params: ['wallet']
                }
                
            },

            pageConfigBtn: {
                zhiya: {
                    label: '质押',
                    abiName: 'mint',
                    params: ['']
                },
                zhiyau: {
                    label: '质押u',
                    abiName: 'mintU',
                    params: ['']
                },
                lingqu: {
                    label: '领取',
                    abiName: 'withdraw',
                },
            },

            pageConfig: {

                dengji: {

                    label: '等級',
                    abiName: 'getUserLevel',
                    isNotNumber: true,
                    value: '',
                    params: ['wallet']
                },

                shangji: {
                    label: '推荐人',
                    abiName: 'getRecommend',
                    isNotNumber: true,
                    value: '',
                    params: ['wallet']
                },
                xiaji: {
                    label: '下级',
                    abiName: 'getLow',
                    isNotNumber: true,
                    value: [],
                    params: ['wallet']
                },

                quanwangsuanli: {
                    label: '全网算力',
                    abiName: 'getTotalPower',
                    value: '',
                    bigNumer: 20,
                    params: []
                },

                shouyi: {
                    label: '累计收益',
                    abiName: 'getLTotalReward',
                    value: '',
                    params: ['wallet']
                },

                kelingqushouyi: {
                    label: '可领取收益',
                    abiName: 'getReward',
                    value: '',
                    params: ['wallet']
                },
                
            }
        }
    },
    created() {
        this.setContent()
        this.loadData()
    },

    methods: {
        loadData() {
            const wallet = this.wallet
            // const wallet = '0x4E757051Ee465846823A049c6969f0658983E19E'



            this.$store.commit("SetLoading", false);

            const { pageConfig, pageConfigNFT, abi } = this

            for (let key in pageConfig) {
                abi(key)
            }

            // 获取算力
            this.SuanliFun()

            this.SuanliDanJia()
            this.ZhiYaJiLu()
            this.ShouYiJiLu()
            this.LingquJiLu()
            this.GetLowList()
        },

        handleValue(e) {
            console.log('', e);
        },


        async setContent() {

            const shareurl = this.$route.query.shareurl

            const ongHaveBind = await this.testBind(this.wallet)

            if (ongHaveBind) {
                this.$store.commit('SetBindAddressNFT', ongHaveBind)
                this.$store.commit('SetBindStateNFT', true)
            } else {

                if (shareurl) {

                    if (shareurl === this.wallet) {

                        this.$store.commit('SetBindAddressNFT', contractToken.address)
                    } else {


                        const shareUrlHaveBind = await this.testBind(shareurl)
                        if (shareUrlHaveBind) {

                            this.$store.commit('SetBindAddressNFT', shareurl)
                        } else {
                            this.$store.commit('SetBindAddressNFT', contractToken.address)
                        }
                    }
                } else {
                    this.$store.commit('SetBindAddressNFT', contractToken.address)
                }
            }
        },

        SuanliFun() {

            const record = this.pageConfigOther['suanli']
            abi(
                {
                    abiName: record.abiName,
                    params: [this.wallet],
                    type: "check",
                },
                ({ code, data }) => {
                    if (code == 200) {
                        
                        this.pageConfigOther['suanli'].value = data.map(item => {
                            return item == 0 ? 0 : (item / (10 ** 20)).toFixed(3)
                        })

                        console.log('this.pageConfigOther[suanli].value', this.pageConfigOther['suanli'].value);

                        const ownData = Number(data[0]) + Number(data[1]) + Number(data[2])

                        this.pageConfigOther['gerensuanli'].value = ownData == 0 ? 0 : (ownData / (10 ** 20)).toFixed(3)



                        console.log(record.label, "-", record.abiName, ":", this.pageConfigOther['suanli'].value)
                        this.$forceUpdate()
                    }
                }
            );
        },

        SuanliDanJia() {


            const record = this.pageConfigOther['suanligongshi']
            const NumberData = (ethers.BigNumber.from(Number(record.params[0]).toFixed(0))).mul('1000000000000000000')

            abi(
                {
                    abiName: record.abiName,
                    params: [NumberData],
                    type: "check",
                },
                ({ code, data }) => {

                    if (code == 200) {

                        this.pageConfigOther['suanligongshi'].value = data == 0 ? 0 : (data / (10 ** 18)).toFixed(3)


                        console.log(record.label, "-", record.abiName, ":", this.pageConfigOther['suanligongshi'].value)
                        this.$forceUpdate()
                    }
                }
            );
        },

        // 质押记录
        ZhiYaJiLu() {


            const record = this.pageConfigOther['zhiyajilu']
            abi(
                {
                    abiName: record.abiName,
                    params: [this.wallet],
                    type: "check",
                },
                ({ code, data }) => {

                    if (code == 200) {

                        this.pageConfigOther['zhiyajilu'].value = data || []


                        console.log(record.label, "-", record.abiName, ":", this.pageConfigOther['zhiyajilu'].value)
                        this.$forceUpdate()
                    }
                }
            );
        },

        LingquJiLu() {

            const record = this.pageConfigOther['lingqujilu']
            abi(
                {
                    abiName: record.abiName,
                    params: [this.wallet],
                    type: "check",
                },
                ({ code, data }) => {

                    if (code == 200) {

                        this.pageConfigOther['lingqujilu'].value = data || []


                        console.log(record.label, "-", record.abiName, ":", this.pageConfigOther['lingqujilu'].value)
                        this.$forceUpdate()
                    }
                }
            );
        },

        // 收益记录

        ShouYiJiLu() {


            const record = this.pageConfigOther['shouyijilu']
            abi(
                {
                    abiName: record.abiName,
                    params: [this.wallet],
                    type: "check",
                },
                ({ code, data }) => {

                    if (code == 200) {

                        this.pageConfigOther['shouyijilu'].value = data || []


                        console.log(record.label, "-", record.abiName, ":", this.pageConfigOther['shouyijilu'].value)
                        this.$forceUpdate()
                    }
                }
            );
        },

        GetLowList() {

            const record = this.pageConfigOther['xiajidizhi']

            abi(
                {
                    abiName: record.abiName,
                    params: [this.wallet],
                    type: "check",
                },
                async ({ code, data }) => {

                    if (code == 200) {
                        // this.pageConfigOther['xiajidizhi']
                        for (let index = 0; index < data.length; index++) {
                            const suanli = await this.GetLowSuanli(data[index])
                            const dengji = await this.GetLowDengji(data[index])
                            const jiangli = await this.GetMySuanli(data[index])
                            

                            this.pageConfigOther['xiajidizhi'].value[index] = {
                                address: data[index],
                                suanli: suanli,
                                dengji: dengji,
                                jiangli: jiangli
                            }
                        }
                        console.log(record.label, "-", record.abiName, ":", this.pageConfigOther['xiajidizhi'].value)
                        this.$forceUpdate()
                    }
                }
            );
        },


        GetLowSuanli(address) {
            return new Promise(resolve => {

                const record = this.pageConfigOther['tigongsuanli']
                abi(
                    {
                        abiName: record.abiName,
                        params: [address],
                        type: "check",
                    },
                    ({ code, data }) => {

                        if (code == 200) {
                            resolve(data)
                        }
                    }
                );
            })
        },

        GetLowDengji(address) {
            return new Promise(resolve => {

                const record = this.pageConfigOther['xiajidengji']
                abi(
                    {
                        abiName: record.abiName,
                        params: [address],
                        type: "check",
                    },
                    ({ code, data }) => {

                        if (code == 200) {
                            resolve(data)
                        }
                    }
                );
            })
        },

        GetMySuanli(address) {

            return new Promise(resolve => {
                const record = this.pageConfigOther['suanli']
                abi(
                    {
                        abiName: record.abiName,
                        params: [address],
                        type: "check",
                    },
                    ({ code, data }) => {
                        if (code == 200) {

                            const ownData =  +(Number(data[0] || 0) / (10 ** 20)).toFixed(3)
                            resolve(ownData)
                        }
                    }
                );
            })
        },

        abi(key) {
            const record = this.pageConfig[key]
            let params = record.params
            if (record.params.length > 0) {
                if (record.params[0] === 'wallet') {
                    params[0] = this.wallet
                }
            }
            abi(
                {
                    abiName: record.abiName,
                    params: params,
                    type: "check",
                },
                ({ code, data }) => {
                    if (code == 200) {

                        if (this.pageConfig[key].isNotNumber) {
                            this.pageConfig[key].value = data
                        } else {
                            if (this.pageConfig[key].isNotNeed18) {
                                this.pageConfig[key].value = data == 0 ? 0 : (data);
                            } else {
                                this.pageConfig[key].value = data == 0 ? 0 : (data / (10 ** (record.bigNumer || 18))).toFixed(this.pageConfig[key].toFixedNumber === 0 ? 0 : 3);
                            }
                        }

                        console.log(record.label, "-", record.abiName, ":", this.pageConfig[key].value)
                        this.$forceUpdate()
                    }
                }
            );
        },



        abiFun(record) {
            if (!this.wallet) {
                this.showSuccessMessage("请先连接钱包!");
            } else {

                this.$store.commit("SetLoadingText", `领取中`);
                this.$store.commit("SetLoading", true);

                abi(
                    {
                        abiName: record.abiName,
                        params: record.params ? record.params : []
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            this.loadData()
                        } else {
                            this.showSuccessMessage("取消操作!");
                            this.$store.commit("SetLoading", false);
                        }
                    }
                );
            }
        },

        errorMessage(type) {

            if (type == 'token') {
                this.showSuccessMessage(`请输入不小于1T的VCD`);
                return
            } else if (type == 'usdt') {
                this.showSuccessMessage(`请输入不小于100的usdt`);
                return
            }
        },

        /* 先根据指示授权 再执行 */
        /* 1绑定2授权3私募 */
        async abiFunApprove(record, type) {

            // if(!!!record.value)return


            let status = false
            if (!this.wallet) {

                this.showSuccessMessage("请先连接钱包!");
            } else {



                status = await this.Bind(record.value, type)
                console.log('status1', status)
                if (!status) return

                status = await this.approve(type)
                console.log('status2', status)
                if (!status) return


                this.$store.commit("SetLoadingText", `质押中`);
                this.$store.commit("SetLoading", true);

                const NumberData = (ethers.BigNumber.from(Number(record.value) * 10000000000)).mul('100000000')

                abi(
                    {
                        abiName: record.abiName,
                        params: [NumberData]
                    },
                    ({ code, data }) => {
                        if (code == 200) {
                            this.loadData()
                        } else {
                            this.showSuccessMessage("取消操作");
                        }
                        this.$store.commit("SetLoading", false);
                    }
                );
            }


        },


        /* 查询绑定人 */
        testBind(address) {

            const wallet = address
            // const wallet = '0x4E757051Ee465846823A049c6969f0658983E19E'
            return new Promise(resolve => {
                abi({
                    abiName: "getRecommend",
                    type: "check",
                    params: [wallet],
                },
                    async ({
                        code,
                        data,
                        message
                    }) => {
                        if (!data || data == '0x0000000000000000000000000000000000000000') {
                            resolve(false)
                        } else {
                            resolve(data)
                        }
                    }
                );
            })

        },


        Bind() {

            if (this.bindAddress === this.wallet) {

                this.showSuccessMessage("无法与自己绑定!");
                this.$store.commit("SetLoading", false);
                resolve(false)
            } else {
                return new Promise((resolve) => {
                    if (!this.bindState) {

                        this.$store.commit("SetLoadingText", '绑定中');
                        this.$store.commit("SetLoading", true);
                        abi({
                            abiName: 'bind',
                            params: [this.wallet, this.bindAddress]
                        }, ({ code, message }) => {
                            if (code == 200) {
                                this.$store.commit("SetBindStateNFT", true);
                                this.$store.commit("SetBindAddressNFT", this.bindAddress);
                                resolve(true)
                            } else {
                                this.showSuccessMessage("无效账户无法推广!");
                                this.$store.commit("SetBindStateNFT", false);
                                this.$store.commit("SetLoading", false);
                                resolve(false)
                            }
                        })
                    } else {
                        resolve(true)
                    }
                })
            }
        },


        allowance(type) {

            return new Promise(async resolve => {
                if (type === 'token') {
                    const state = await allowanceToken()

                    console.log(Number(state))

                    const stateB = (state > 0 && (state / (10 ** 18)) > 1000) ? true : false
                    this.approveState = stateB
                    resolve(stateB)
                } else {
                    const state = await allowance()

                    console.log(Number(state))
                    const stateB = (state > 0 && (state / (10 ** 18)) > 1000) ? true : false
                    this.approveState = stateB
                    resolve(stateB)
                }
            })


        },


        approve(type) {

            return new Promise(async (resolve) => {
                const state = await this.allowance(type)
                console.log('state', state)
                if (state) {
                    resolve(true)
                } else {
                    this.$store.commit("SetLoadingText", '授权中');
                    this.$store.commit("SetLoading", true);

                    if (type == 'token') {
                        approveToken((data) => {
                            if (data) {
                                this.approveState = true
                                resolve(true)
                            } else {
                                this.approveState = false
                                this.$store.commit("SetLoading", false);
                                this.showSuccessMessage("取消操作!");
                                this.$store.commit("SetLoading", false);
                                resolve(false)
                            }
                        })
                    } else {
                        approve((data) => {
                            if (data) {
                                this.approveState = true
                                resolve(true)
                            } else {
                                this.approveState = false
                                this.$store.commit("SetLoading", false);
                                this.showSuccessMessage("取消操作!");
                                this.$store.commit("SetLoading", false);
                                resolve(false)
                            }
                        })
                    }

                }

            })

        },


        // 分享
        Share() {
            if (!this.wallet) {
                this.showSuccessMessage('请连接钱包!')
            } else if (this.pageConfigOther['gerensuanli'].value <= 0) {
                this.showSuccessMessage('无效账户无法推广!')
            } else {
                const url = window.location.href.split('#')[0]
                const param = this.wallet || ''
                const content = `${url}#/?shareurl=${param}`
                var aux = document.createElement("input");
                aux.setAttribute("value", content);
                document.body.appendChild(aux);
                aux.select();
                document.execCommand("copy");
                document.body.removeChild(aux);
                this.showSuccessMessage('复制成功！')
            }
        },

        filterNumber18(data) {
            return (data / (10 ** 18)).toFixed(2)
        },
        filterNumber20(data) {
            return (data / (10 ** 20)).toFixed(2)
        },

        filterDate(data) {
            const timestamp = data; // 你的10位时间戳

            // 将时间戳转换为毫秒
            const date = new Date(timestamp * 1000);

            // 获取各个部分
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            // 格式化为 "年-月-日 时:分:秒"
            const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            return (formattedDate);
        }
    }
}
</script>


<style scoped lang="scss">
.Home {
    // width: 100%;
    width: 375px;
    margin: 0 auto;
    // height: 100vh;
    // background-color: #030C38;
    color: #fff;
    padding-top: 72px;
    padding-bottom: 72px;
    overflow-y: auto;

    .font-style {
        font-weight: 700;
        font-style: italic;
        background: -webkit-linear-gradient(270deg, #4EAC92 0%, #557ECD 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        font-size: 16px;
    }

    .part {
        text-align: center;
        font-style: normal;
        text-transform: none;
        font-family: DIN, DIN;
        font-weight: bold;
        margin-bottom: 40px;
    }
}

.tjrdz {
    width: 326px;
    height: 97px;
    background-image: url('../../assets/img/1115/矩形 2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    margin: 0 auto;

    .t-name {
        display: flex;
        justify-content: space-between;
        padding: 8px 12px 6px 14px;
        font-size: 12px;
        color: #fff;
        box-sizing: border-box;

        .t-name--left {
            display: flex;
            align-items: center;
        }

        img {
            width: 13px;
            height: 11px;
            margin-right: 5px;
        }
    }

    .tjr-content {
        display: flex;
        justify-content: space-between;
        width: 326px;
        height: 67px;
        background-image: url('../../assets/img/1115/组 2.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .tjr-left {
            display: flex;
            align-items: center;
            margin-left: 14px;

            img {
                width: 12px;
                height: 12px;
                margin-right: 7px;
            }

            .my-address {
                margin-right: 10px;
            }
        }

        .tjr-right {
            position: relative;
            right: -2px;
            top: -1px;
            bottom: -1px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            width: 95px;
            height: 69px;

            .tjr-img {
                position: absolute;
                left: 0px;
                right: 10px;
                width: 100%;
                height: 100%;
            }

            .share-img {
                position: absolute;
                left: 60%;
                top: 35%;
                transform: translate(-50%, -50%);
                width: 24%;
                height: 22px;
            }

            .right-share {
                position: absolute;
                left: 60%;
                top: 73%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.need-vcb {
    width: 326px;
    // height: 201px;
    margin: 0 auto;
    margin-top: 15px;
    background-image: url('../../assets/img/1115/矩形 2 拷贝 4.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 9px;

    .vcb-title {
        display: flex;
        padding-top: 11px;
        padding-left: 15px;
        padding-right: 11px;
        justify-content: space-between;
        margin-bottom: 8px;

        .vcb-value {
            font-weight: 700;
            font-size: 16px;
            color: #FFFFFF;
            background: linear-gradient(0deg, #7340F7 0%, #E03AC8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .vcb-content {
        padding: 10px 5px;
        box-sizing: border-box;
        .v-1,.v-2,.v-3,.v-4 {
            width: 154px;
            min-height: 75px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding: 15px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .v-title {
                font-weight: 600;
                font-size: 12px;
                color: #777777;
                &.yingyu {
                    font-size: 10px;
                }
            }

            .v-value {
                font-weight: 600;
                font-size: 18px;
                color: #FFFFFF;
                &.v2-v {
                    font-weight: 600;
                    font-size: 25px;
                    color: #FFFFFF;
                    background: linear-gradient(0deg, #7340F7 0%, #E03AC8 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        .v-top,.v-bottom {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        .v-1 {
            background-image: url('../../assets/img/1115/矩形 2 拷贝 9.png');
        }

        .v-2 {
            background-image: url('../../assets/img/1115/矩形 2 拷贝 11.png');
        }

        .v-3 {
            background-image: url('../../assets/img/1115/矩形 2 拷贝 10.png');
        }

        .v-4 {
            background-image: url('../../assets/img/1115/矩形 2 拷贝 12.png');
        }
    }
}

.suanli-card {
    display: flex;
    justify-content: space-between;
    margin: 0 25px;
    margin-bottom: 10px;
    .s-card {
        display: flex;
        width: 102px;
        min-height: 60px;
        padding: 8px 10px 0px 10px;
        box-sizing: border-box;
        justify-content: space-between;
        background-image: url('../../assets/img/1115/矩形 2 拷贝 13.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        &.card2 {
            background-image: url('../../assets/img/1115/矩形 2 拷贝 14.png');
        }
        &.card3 {
            background-image: url('../../assets/img/1115/矩形 2 拷贝 15.png');
        }
        .card-left {
            .c-left--top {
                margin-bottom: 10px;
            }   
        }
        .card-right {
            img {
                width: 9px;
                height: 9px;
            }
        }
    }
}

.income {
    margin: 0 auto;
    width: 326px;
    height: 77px;
    background-image: url('../../assets/img/1115/矩形 2 拷贝 18.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 22px;
    .income-title {
      padding: 16px;
      display: flex;
      justify-content: space-between;
    }
    .income-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 326px;
        height: 35px;
        background-image: url('../../assets/img/1115/矩形 2 拷贝 17.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}

.search-hash {
    margin: 0 auto;
    width: 326px;
    min-height: 170px;
    background-image: url('../../assets/img/1115/矩形 2 拷贝 3.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 16px;
    .hash-title {
        padding: 8px 12px 8px 16px;
        display: flex;
        justify-content: space-between;
        .t-right {
            font-weight: 600;
            font-size: 12px;
            color: #FFFFFF;
            background: linear-gradient(0deg, #7340F7 0%, #E03AC8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .hash-content {
        margin: 0 5px;
        display: flex;
        justify-content: space-between;;
        .hash-left,.hash-right {
            position: relative;
            // width: 154px;
            width: 100%;
            height: 124px;
            background-image: url('../../assets/img/1115/矩形 2 拷贝 5.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .hash-right {
            background-image: url('../../assets/img/1115/矩形 2 拷贝 6.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .zynum-img {
            position: absolute;
            right: 12px;
            top: 12px;
            img {
                width: 19px;
                height: 19px;
            }
        }
        .input-num {
            padding-top: 13px;
            padding-left: 15px;
            font-size: 12px;
            color: #777;
            margin-bottom: 14px;
            .input-pl {
                height: 20px;
                color: rgba(255, 255, 255, 0.3);
            }
            input {
                // width: 100px;
                width: 100%;
                height: 40px;
                border-color: transparent;
                background-color: transparent;
                color: #fff;
                font-size: 22px;
            }
            input::input-placeholder {
                color: rgba(255, 255, 255, 0.3);
            }
        }
        .num {
            font-size: 25px;
            color: #FFFFFF;
            padding-left: 16px;
        }
        .zy-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0%;
            // width: 154px;
            width: 100%;
            height: 39px;
            background-image: url('../../assets/img/1115/矩形 2 拷贝 17.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
            &.u {
                background-image: url('../../assets/img/1115/矩形 2 拷贝 17.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}

.choose-record {
    width: 326px;
    margin: 0 auto;
    margin-bottom: 10px;
    /deep/ .van-dropdown-menu__bar {
        width: 326px;
        height: 39px;
        background-image: url('../../assets/img/1115/矩形 2 拷贝 21.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: transparent;
        
    }
    /deep/ .van-popup {
            z-index: 2008;
            width: 226px;
            left: 50%;
            transform: translateX(-50%);
    }
    /deep/ .van-ellipsis {
        color: #fff;
    }

    
}

.zy-list {
    width: 326px;
    min-height: 174px;
    background-image: url('../../assets/img/1115/组 4 拷贝.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
    margin: 0 auto;
    .zy-title {
        display: flex;
        height: 42px;
        align-items: center;
        justify-content: center;
    }
    img {
        display: block;
        width: 298px;
        height: 1px;
        margin: 0 auto;
    }

    .zy-content {
        // height: 130px;
        overflow: auto;
    }
    .record-item {
        // height: 48px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(136, 136, 136, 0.3);

        .r-title {
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            margin-bottom: 6px;

            display: flex;

            .tag {
                padding: 1px 4px 0px;
                color: #fff;
                border-radius: 2px;
                text-align: center;
                margin-right: 5px;
            }

            .tag-u {
                background-color: #4EAC92;
            }

            .tag-t {
                background-color: #557ECD;
            }

        }


        .r-time {
            font-size: 12px;
            color: #999999;
        }

        .i-right {
            font-weight: 500;
            font-size: 24px;
            color: #FFFFFF;

            &.level {
                font-weight: 700;
                color: rgb(78, 172, 146);
                transform: scale(1.2);
                margin-right: 5px;
            }

        }
    }
}

.himg {
    width: 326px;
    height: 162px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 16px;
    img {
        width: 100%;
        height: 100%;
    }
}
</style>
